import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';

// tslint:disable no-default-export
export default () => (
  <>
    <Helmet title="tinkertaylor.dev" />

    <Intro>
    Specialised full stack development, consulting, and training in <Highlight>.NET</Highlight>,&nbsp;
      <Highlight>JavaScript</Highlight>, <Highlight>TypeScript</Highlight>, using <Highlight>Agile</Highlight> and <Highlight>DevOps</Highlight> methodologies.
    </Intro>

    <BlockText
      description={(
        <>
          I'm Emily Taylor, a full-service independant consultant who keeps busy
          designing and building specialised solutions. <a
            href="mailto:emily@tinkertaylor.dev"
          >
            Get in contact
          </a> to talk about development, consulting or training.
        </>
      )}
    />
  </>
);
